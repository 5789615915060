<template>
    <acs-base-container large size="large">
        <v-card flat>
            <v-list three-line>
                <acs-product-translate
                    :loading="loading"
                    v-model="translate"
                    @change="load()" />

                <template v-for="product in products">
                    <v-subheader
                        v-if="product.upselling_title"
                        :key="`h-${product.id}`"
                        class="grey lighten-3">
                        <div class="acs-product-list-category-breadcrumb">
                            {{ $options.filters.quickTranslate(product, 'upselling_title', translate) }}
                        </div>
                    </v-subheader>

                    <acs-product-list-item
                        :key="product.id"
                        :slug="slug"
                        :product="product"
                        :translate="translate"
                        popup
                        @click="onSelect(product)" />

                    <v-divider :key="`i-${product.id}`" />
                </template>
            </v-list>

            <v-dialog v-model="showProduct" max-width="600" :persistent="loading" :fullscreen="$vuetify.breakpoint.smAndDown" scrollable>
                <acs-product-form
                    v-if="selected"
                    :slug="slug"
                    :product-id="selected.id"
                    @product:add="showProduct = false"
                    hide-cart-icon>
                    <template #title="{ loading }">
                        <acs-base-card-title :title="$t('productupselling.title')" :dimissible="loading" @close="showProduct = false" />
                    </template>
                </acs-product-form>
            </v-dialog>

            <v-card-actions>
                <v-btn
                    class="primary"
                    block
                    rounded
                    :disabled="loading"
                    :loading="loading"
                    @click="next">
                    {{ $t('productupselling.next') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </acs-base-container>
</template>

<script>
import AcsBaseContainer from '@/components/base/AcsBaseContainer'
import AcsBaseCardTitle from '@/shared/components/base/AcsBaseCardTitle'
import AcsProductListItem from '@/components/product-list/AcsProductListItem'
import AcsProductTranslate from '@/components/product-list/AcsProductTranslate'
import AcsProductForm from '@/components/product/AcsProductForm'

import RouteMixin from '@/mixins/RouteMixin'

export default {
    name: 'cart-upselling',

    components: {
        AcsBaseContainer,
        AcsBaseCardTitle,
        AcsProductListItem,
        AcsProductTranslate,
        AcsProductForm
    },

    mixins: [RouteMixin],

    props: {
        slug: { type: String },
        aslot: { type: String }
    },

    data: () => ({
        loading: false,
        translate: false,
        showProduct: false,
        selected: null
    }),

    computed: {
        serviceId() {
            return this.$store.getters['cart/cart']?.service_id
        },

        service() {
            return this.$store.getters['ser/services'].find(s => s.id === this.serviceId)
        },

        products() {
            return this.$store.getters['ser/upsellingProducts']
        },

        timeframesToggle() {
            return this.$store.getters['ser/timeframesToggle']
        }
    },

    watch: {
        timeframesToggle: 'load'
    },

    mounted() {
        this.translate = this.$store.getters['prod/translate']
    },

    methods: {
        load() {
            this.loading = true
            return Promise
                .all([
                    this.$store.dispatch('cart/get', { slug: this.slug }),
                    this.$store.dispatch('ser/services', { slug: this.slug }),
                    this.$store.dispatch('sp/current', { slug: this.slug }),
                    this.$store.dispatch('prod/categories', { slug: this.slug }),
                    this.aslot && this.$store.dispatch('sp/slot', { slug: this.slug, slot: this.aslot })
                ])
                .then(([cart]) => {
                    return this.$store.dispatch('ser/upselling', {
                        slug: this.slug,
                        service_id: cart.service_id,
                        timeframes: cart.timeframes,
                        translate: this.translate
                    })
                })
                .then(() => {
                    if (!this.products.length) {
                        return this.next()
                    }
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        },

        onSelect(product) {
            this.selected = product
            this.showProduct = true
        },

        async next() {
            // check s'il y a des données avancées à saisir
            this.loading = true
            return this.$store
                .dispatch('ser/advanced', {
                    slug: this.slug,
                    service_id: this.serviceId
                })
                .then(advanced => {
                    const showAdvanced = advanced && (advanced.price || advanced.options?.length)
                    const showTimeframe = this.service?.timeframe?.options?.length
                    return this.$router.push({ name: this.getContextRouteName(showAdvanced || showTimeframe ? 'cartadvanced' : 'cartpay') })
                })
                .catch(err => this.$err(err))
                .finally(() => (this.loading = false))
        }
    }
}
</script>
